import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/layout/App.vue';
import Home from './home.js';
import Entrance from './entrance.js';
import Push from './push.js';
import Gray from './gray.js';
import LoveTask from './love-task.js';
import LoveAdvice from './love-advice.js';
import Pushv2 from './pushv2.js';
import Cubbily from './cubbily.js';
import CubbilyQuiz from './cubbily-quiz.js';
import app from '@/config/app.js';

Vue.use(VueRouter);

const contentRoute = [
  ...Home,
  ...Entrance,
  ...Push,
  ...Gray,
  ...LoveTask,
  ...LoveAdvice,
  ...Pushv2,
  ...Cubbily,
  ...CubbilyQuiz
];

const routes = [
  { path: '/', name: 'home', redirect: '/home' },
  {
    path: '/',
    component: Layout,
    children: contentRoute
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '后台登录',
      keepAlive: false
    },
    components: {
      blank: (resolve) => require(['@/views/login/Login.vue'], resolve)
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

if (app.serviceEnv != 'local') {
  const vm = Vue.prototype;

  // 挂载路由导航守卫
  router.beforeEach((to, from, next) => {
    // 访问登录页，放行
    if (to.path === '/login') {return next();}
    // 获取token
    const tokenStr = vm.$cookie.get('accessToken');
    // 没有token, 强制跳转到登录页
    if (!tokenStr) {
      return next('/login');
    } else {
      app.globalData.accessToken = tokenStr;
    }
    next();
  });
}

export default router;
