/**
 * 首页
 * @type {{name: string, path: string, icon: string}}
 */

const home = {
  name: '首页',
  path: '/home',
  permissionsKey: '',
  icon: 'fa fa-tachometer'
};

const entrance = {
  name: '功能入口管理',
  path: '/entrance',
  permissionsKey: '',
  icon: 'fa fa-grav',
  children: {
    discover: {
      name: '发现页配置',
      permissionsKey: '',
      path: '/entrance/discover'
    },
    game: {
      name: '小世界配置',
      permissionsKey: '',
      path: '/entrance/game'
    },
    homeAlert: {
      name: '首页活动弹窗',
      permissionsKey: '',
      path: '/entrance/home-alert'
    }
  }
};

const push = {
  name: '消息推送',
  path: '/push',
  permissionsKey: '',
  icon: 'fa fa-bell-o',
  children: {
    discover: {
      name: '发送消息',
      permissionsKey: '',
      path: '/push/index'
    },
    game: {
      name: '消息记录',
      permissionsKey: '',
      path: '/push/history'
    }
  }
};

const gray = {
  name: '灰度管理',
  path: '/gray',
  permissionsKey: '',
  icon: 'fa fa-file-text',
  children: {
    discover: {
      name: '接口列表',
      permissionsKey: '',
      path: '/gray/index'
    }
  }
};

const loveTask = {
  name: '恋爱锦囊',
  path: '/love-task',
  permissionsKey: '',
  icon: 'fa fa-bell-o',
  children: {
    discover: {
      name: '每日任务库',
      permissionsKey: '',
      path: '/love-task/daily'
    },
    game: {
      name: '任务备选库',
      permissionsKey: '',
      path: '/love-task/reserve'
    }
  }
};

const loveAdvice = {
  name: '甜蜜小建议',
  path: '/love-advice',
  permissionsKey: '',
  icon: 'fa fa-opencart',
  children: {
    task: {
      name: '任务库',
      permissionsKey: '',
      path: '/love-advice/task'
    },
    example: {
      name: '示例库',
      permissionsKey: '',
      path: '/love-advice/example'
    },
    audit: {
      name: '审核库',
      permissionsKey: '',
      path: '/love-advice/audit'
    }
  }
};

const pushv2 = {
  name: '消息推送v2',
  path: '/pushv2',
  permissionsKey: '',
  icon: 'fa fa-bell-o',
  children: {
    history: {
      name: '推送记录',
      permissionsKey: '',
      path: '/pushv2/history'
    }
  }
};

const cubbily = {
  name: 'Cubbily数据后台',
  path: '/cubbily-quiz',
  permissionsKey: '',
  icon: 'el-icon-pie-chart',
  children: {
    discover: {
      name: 'UserBehavior',
      permissionsKey: '',
      path: '/cubbily/user-behavior'
    },
    game: {
      name: 'UserFeedback',
      permissionsKey: '',
      path: '/cubbily/user-feedback'
    }
  }
};

const cubbilyQuiz = {
  name: '家庭小问答',
  path: '/cubbily-quiz',
  permissionsKey: '',
  icon: 'fa fa-grav',
  children: {
    normal: {
      name: '默认题目',
      permissionsKey: '',
      path: '/cubbily-quiz/normal'
    },
    festival: {
      name: '节日题目',
      permissionsKey: '',
      path: '/cubbily-quiz/festival'
    },
    offline: {
      name: '下线题目',
      permissionsKey: '',
      path: '/cubbily-quiz/offline'
    }
  }
};

export default {
  home,
  entrance,
  push,
  gray,
  loveTask,
  loveAdvice,
  pushv2,
  cubbily,
  cubbilyQuiz
};
