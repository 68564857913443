export default [
  {
    path: 'love-advice',
    name: 'loveAdvice',
    component: (resolve) => require(['@/views/blank/Blank.vue'], resolve),
    children: [{
      path: 'task',
      name: 'LoveAdviceTask',
      meta: {
        title: '任务库'
      },
      component: (resolve) => require(['@/views/love-advice/Task.vue'], resolve)
    },
    {
      path: 'example',
      name: 'LoveAdviceExample',
      meta: {
        title: '示例库'
      },
      component: (resolve) => require(['@/views/love-advice/Example.vue'], resolve)
    },
    {
      path: 'audit',
      name: 'LoveAdviceAudit',
      meta: {
        title: '审核库'
      },
      component: (resolve) => require(['@/views/love-advice/Audit.vue'], resolve)
    }]
  }

];
