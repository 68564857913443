export default [
  {
    path: 'gray',
    name: 'grayBlank',
    component: (resolve) => require(['@/views/blank/Blank.vue'], resolve),
    children: [
      {
        path: 'index',
        name: 'grayIndex',
        meta: {
          title: '灰度列表'
        },
        component: (resolve) =>
          require(['@/views/gray/Index.vue'], resolve)
      }
    ]
  }
];
