const weloveTestUrl = 'https://welove-appmgrtt.welove520.com';
const weloveUrl = 'https://welove-appmgr.welove520.com';
const sweetTestUrl = 'https://sweet-appmgrtt.welove520.com';
const sweetUrl = 'https://sweet-appmgr.welove520.com';
const sweetusTestUrl = 'https://appmgrtt.sweet-us.com';
const sweetusUrl = 'https://appmgr.sweet-us.com';
const cubbilyTestUrl = 'https://appmgrtt.cubbily.com';
const cubbilyUrl = 'https://appmgr.cubbily.com';

let env;
let serviceEnv;
let useApiUrl = weloveTestUrl;
let consoleApiUrl = 'https://consolett.welove520.com';

console.log('获取当前页面url:', window.location.href);
if (window.location.href.indexOf('http://localhost') >= 0) {
  useApiUrl = '/api';
  serviceEnv = 'local';
}
if (window.location.href.indexOf(weloveTestUrl) >= 0) {
  useApiUrl = `${weloveTestUrl}/manage`;
  serviceEnv = 'weloveTest';
}
if (window.location.href.indexOf(weloveUrl) >= 0) {
  useApiUrl = `${weloveUrl}/manage`;
  serviceEnv = 'welove';
  consoleApiUrl = 'https://console.welove520.com';
}
if (window.location.href.indexOf(sweetTestUrl) >= 0) {
  useApiUrl = `${sweetTestUrl}/manage`;
  serviceEnv = 'sweetTest';
  consoleApiUrl = 'https://sweet-consolett.welove520.com';
}
if (window.location.href.indexOf(sweetUrl) >= 0) {
  useApiUrl = `${sweetUrl}/manage`;
  serviceEnv = 'sweet';
  consoleApiUrl = 'https://sweet.console.welove520.com';
}
if (window.location.href.indexOf(sweetusTestUrl) >= 0) {
  useApiUrl = 'https://consolett.sweet-us.com';
  serviceEnv = 'sweetusTest';
}
if (window.location.href.indexOf(sweetusUrl) >= 0) {
  useApiUrl = 'https://console.sweet-us.com';
  serviceEnv = 'sweetus';
}
if (window.location.href.indexOf(cubbilyTestUrl) >= 0) {
  useApiUrl = 'https://consolett.cubbily.com';
  serviceEnv = 'cubbilyTest';
}
if (window.location.href.indexOf(cubbilyUrl) >= 0) {
  useApiUrl = 'https://console.cubbily.com';
  serviceEnv = 'cubbily';
}

if (process.env.NODE_ENV === 'development') {
  env = 'dev';
} else {
  // production
  switch (process.env.VUE_APP_MODE) {
  case 'buildDev':
    env = 'dev';
    break;
  case 'buildTest':
    env = 'dev';
    break;
  case 'buildPre':
    env = 'dev';

    break;
  default:
    env = 'pro';
    break;
  }
}

console.log(process.env.NODE_ENV);

export { useApiUrl, consoleApiUrl, env, serviceEnv}; // 可以导出更多需要不同环境区分的url
