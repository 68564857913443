<template>
  <div class="body-top">
    <div class="body-top-btn" @click="hiddenSidebar">
      <i class="el-icon-menu"></i>
    </div>
    <div class="right">
      <el-select
        v-model="currentEnv"
        @change="selectEnv"
        placeholder="选择环境"
      >
        <el-option
          v-for="item in envOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span class="body-top-btn" @click="screenFullToggle">
        <i class="fa fa-arrows-alt"></i>
      </span>
    </div>
  </div>
</template>

<script>
import ScreenFull from 'screenfull';
import { mapState } from 'vuex';
import Menu from '@/menu/index';
import app from '@/config/app.js';
export default {
  name: 'BodyTop',
  data () {
    return {
      menu: Menu,
      userName: 'Admin',
      envOptions: [
        {
          value: 0,
          label: '微爱测试'
        },
        {
          value: 1,
          label: '微爱正式'
        },
        {
          value: 2,
          label: '情侣空间测试'
        },
        {
          value: 3,
          label: '情侣空间正式'
        },
        {
          value: 4,
          label: 'sweetus测试'
        },
        {
          value: 5,
          label: 'sweetus正式'
        },
        {
          value: 6,
          label: 'cubbily测试'
        },
        {
          value: 7,
          label: 'cubbily正式'
        }
      ],
      currentEnv: 0
    };
  },
  methods: {
    hiddenSidebar () {
      this.$store.commit('HIDE_SIDEBAR_TOGGLE');
    },
    screenFullToggle () {
      ScreenFull.toggle()
        .then(() => {})
        .catch(() => {
          this.$message({
            message: '你的浏览器不支持全屏！',
            type: 'warning'
          });
        });
    },

    logout () {},

    selectEnv (e) {
      console.log('选择了环境', e);
      switch (e) {
      case 0:
        window.location = 'https://welove-appmgrtt.welove520.com';
        break;
      case 1:
        window.location = 'https://welove-appmgr.welove520.com';
        break;
      case 2:
        window.location = 'https://sweet-appmgrtt.welove520.com';
        break;
      case 3:
        window.location = 'https://sweet-appmgr.welove520.com';
        break;
      case 4:
        window.location = 'https://appmgrtt.sweet-us.com';
        break;
      case 5:
        window.location = 'https://appmgr.sweet-us.com';
        break;
      case 6:
        window.location = 'https://appmgrtt.cubbily.com';
        break;
      case 7:
        window.location = 'https://appmgr.cubbily.com';
        break;

      default:
        break;
      }
    }
  },
  computed: mapState({
    env: (state) => state.system.env
  }),
  created () {
    switch (app.serviceEnv) {
    case 'local':
      this.currentEnv = 0;
      break;
    case 'weloveTest':
      this.currentEnv = 0;
      break;
    case 'welove':
      this.currentEnv = 1;
      break;
    case 'sweetTest':
      this.currentEnv = 2;
      break;
    case 'sweet':
      this.currentEnv = 3;
      break;
    case 'sweetusTest':
      this.currentEnv = 4;
      break;
    case 'sweetus':
      this.currentEnv = 5;
      break;
    case 'cubbilyTest':
      this.currentEnv = 6;
      break;
    case 'cubbily':
      this.currentEnv = 7;
      break;
    default:
      break;
    }
  }
};
</script>
<style lang="scss">
@import "../../assets/css/variables.scss";
.body-top {
  width: 100%;
  display: flex;
  height: 50px;
  background-color: $--color-primary;
  z-index: 10;
  .body-top-btn {
    overflow: hidden;
    height: $--top-height;
    display: inline-block;
    text-align: center;
    line-height: $--top-height;
    cursor: pointer;
    padding: 0 14px;
    color: #fff;
    .badge {
      .el-badge__content {
        margin-top: 10px;
      }
    }
    &:hover {
      background-color: mix(#000, $--color-primary, 10%);
    }
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
