export default [
  {
    path: 'love-task',
    name: 'loveTaskBlank',
    component: (resolve) => require(['@/views/blank/Blank.vue'], resolve),
    children: [
      {
        path: 'daily',
        name: 'daily',
        meta: {
          title: '每日任务库'
        },
        component: (resolve) =>
          require(['@/views/love-task/Daily.vue'], resolve)
      },
      {
        path: 'reserve',
        name: 'reserve',
        meta: {
          title: '备选任务库'
        },
        component: (resolve) =>
          require(['@/views/love-task/Reserve.vue'], resolve)
      }
    ]
  }
];
