export default [
  {
    path: 'cubbily',
    name: 'cubbilyBlank',
    component: (resolve) => require(['@/views/blank/Blank.vue'], resolve),
    children: [
      {
        path: 'user-behavior',
        name: 'UserBehavior',
        meta: {
          title: 'UserBehavior',
          keepAlive: true
        },
        component: (resolve) =>
          require(['@/views/cubbily/UserBehavior.vue'], resolve)
      },
      {
        path: 'family-info',
        name: 'FamilyInfo',
        meta: {
          title: 'FamilyInfo'
        },
        component: (resolve) =>
          require(['@/views/cubbily/FamilyInfo.vue'], resolve)
      },
      {
        path: 'user-info',
        name: 'UserInfo',
        meta: {
          title: 'UserInfo'
        },
        component: (resolve) =>
          require(['@/views/cubbily/UserInfo.vue'], resolve)
      },
      {
        path: 'user-feedback',
        name: 'UserFeedback',
        meta: {
          title: 'UserFeedback'
        },
        component: (resolve) =>
          require(['@/views/cubbily/UserFeedback.vue'], resolve)
      }
    ]
  }
];
