export default [
  {
    path: 'pushv2',
    name: 'pushv2Blank',
    component: (resolve) => require(['@/views/blank/Blank.vue'], resolve),
    children: [
      {
        path: 'history',
        name: 'pushv2History',
        meta: {
          title: '推送记录'
        },
        component: (resolve) =>
          require(['@/views/pushv2/Index.vue'], resolve)
      }
    ]
  }
];
