export default [
  {
    path: 'cubbily-quiz',
    name: 'CubbilyQuizBlank',
    component: (resolve) => require(['@/views/blank/Blank.vue'], resolve),
    children: [
      {
        path: 'normal',
        name: 'Normal',
        meta: {
          title: '默认题目'
        },
        component: (resolve) =>
          require(['@/views/cubbily-quiz/Normal.vue'], resolve)
      },
      {
        path: 'festival',
        name: 'Festival',
        meta: {
          title: '节日题目'
        },
        component: (resolve) =>
          require(['@/views/cubbily-quiz/Festival.vue'], resolve)
      },
      {
        path: 'offline',
        name: 'Offline',
        meta: {
          title: '下线题目'
        },
        component: (resolve) =>
          require(['@/views/cubbily-quiz/Offline.vue'], resolve)
      }
    ]
  }
];
