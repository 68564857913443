import Vue from 'vue';
import App from './App.vue';
import elementUi from 'element-ui';
import Config from '@/config/app';
import '@/assets/css/style.scss';
import router from './router';
import store from './store';
import cookie from 'vue-cookies';

// import { getToken } from "@/utils/common";

Vue.config.productionTip = false;
Vue.prototype.GlobalCfg = Config;
Vue.prototype.$cookie = cookie;

Vue.use(elementUi);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');