export default [
  {
    path: 'home',
    name: 'Home',
    meta: {
      title: '首页'
    },
    component: (resolve) => require(['@/views/home/Index.vue'], resolve)
  }
];
