import allMenu from '@/menu/index.js';
import { serviceEnv } from '@/config/url';

export const menu = {
  state: allMenu,
  mutations: {},
  getters: {
    displayMenu: (state) => {
      const filterType = {
        local: ['home', 'entrance', 'push', 'gray', 'loveTask', 'loveAdvice', 'pushv2', 'cubbily', 'cubbilyQuiz'],
        weloveTest: ['home', 'entrance', 'push', 'gray', 'loveTask', 'loveAdvice', 'pushv2'],
        welove: ['home', 'entrance', 'push', 'gray', 'loveTask', 'loveAdvice', 'pushv2'],
        sweetTest: ['home', 'entrance', 'pushv2'],
        sweet: ['home', 'entrance', 'pushv2'],
        sweetusTest: ['home', 'entrance'],
        sweetus: ['home', 'entrance'],
        cubbilyTest: ['home', 'cubbily', 'cubbilyQuiz'],
        cubbily: ['home', 'cubbily', 'cubbilyQuiz']
      };
      let filterState = {};
      filterType[serviceEnv].map((key) => {
        filterState[key] = state[key];
      });
      return filterState;
    }
  }
};
