export default [
  {
    path: 'push',
    name: 'pushBlank',
    component: (resolve) => require(['@/views/blank/Blank.vue'], resolve),
    children: [
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '消息推送'
        },
        component: (resolve) =>
          require(['@/views/push/Index.vue'], resolve)
      },
      {
        path: 'history',
        name: 'history',
        meta: {
          title: '推送记录'
        },
        component: (resolve) =>
          require(['@/views/push/History.vue'], resolve)
      }
    ]
  }
];
