export default [
  {
    path: 'entrance',
    name: 'entranceBlank',
    component: (resolve) => require(['@/views/blank/Blank.vue'], resolve),
    children: [
      {
        path: 'discover',
        name: 'discover',
        meta: {
          title: '发现页配置'
        },
        component: (resolve) =>
          require(['@/views/entrance/Discover.vue'], resolve)
      },
      {
        path: 'game',
        name: 'game',
        meta: {
          title: '小世界配置'
        },
        component: (resolve) =>
          require(['@/views/entrance/Game.vue'], resolve)
      },
      {
        path: 'home-alert',
        name: 'HomeAlert',
        meta: {
          title: '首页活动弹窗'
        },
        component: (resolve) =>
          require(['@/views/entrance/HomeAlert.vue'], resolve)
      }
    ]
  }
];
